import React from "react";
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";

import Layout from "./index";
import components from "../designSystem";
import Subscribe from "../sections/subscribe";

function PostLayout({ data }) {
  const { mdx } = data;
  return (
    <MDXProvider components={components}>
      <Layout>
        <section className="flex flex-col w-full items-center justify-center">
          <div className="w-full text-center p-4">
            <h1 className="text-xl sm:text-5xl text-black font-bold tracking-wide">
              {mdx.frontmatter.title}
            </h1>
            <p>Published: {mdx.frontmatter.date}</p>
            <p>Author: {mdx.frontmatter.author}</p>
            <div className="inline-flex flex-col items-center justify-center p-4">
              <img src={mdx.frontmatter.thumbnail.publicURL} />
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{ __html: mdx.frontmatter.caption }}
              ></div>
            </div>
          </div>
          <div className="w-full max-w-6xl text-black py-10 text-lg tracking-wide">
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
        </section>
        <Subscribe />
      </Layout>
    </MDXProvider>
  );
}

PostLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query PostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        date
        author
        caption
        thumbnail {
          publicURL
        }
      }
    }
  }
`;

export default PostLayout;
