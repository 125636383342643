/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";

const TagComponent = (Tag, className) => ({children, ...props}) => {
  return (
    <Tag {...props} className={className}>
      {children}
    </Tag>
  );
};

const components = {
  h1: TagComponent(
    'h1',
    'text-gray-800 mt-12 mb-8 -ml-1 text-xl font-extrabold tracking-wider'
  ),
  h2: TagComponent(
    'h2',
    'text-gray-800 mt-4 mb-4 -ml-1 text-xl font-bold tracking-wide'
  ),
  h3: TagComponent(
    'h3',
    'text-gray-800 mt-4 mb-4 -ml-1 text-lg font-bold'
  ),
  h4: TagComponent(
    'h4',
    'text-gray-800 mt-4 mb-4 -ml-1 text-lg font-semibold'
  ),
  h5: TagComponent(
    'h5',
    'text-gray-800 mt-4 mb-4 -ml-1 text-lg font-medium'
  ),
  h6: TagComponent(
    'h6',
    'text-gray-800 mt-4 mb-4 -ml-1 text-base font-semibold'
  ),
  p: TagComponent(
    'p',
    'text-gray-800 leading-relaxed font-light mt-2 mb-4 tracking-wider'
  ),
  a: TagComponent(
    'a',
    'text-action text-mph-ocean-blue leading-relaxed font-medium mb-8'
  ),
  blockquote: TagComponent(
    'blockquote',
    'border-solid border-l-4 border-gray-300 pl-4'
  ),
  ul: TagComponent('ul', 'list-disc m-4'),
  ol: TagComponent('ol', 'list-decimal m-4'),
  li: TagComponent('li', 'text-gray-800 font-light my-1'),
  strong: TagComponent('strong', 'font-semibold'),
  em: TagComponent('em', 'italic'),
  table: TagComponent('table', 'table-auto w-full text-left'),
  td: TagComponent('td', 'border px-4 py-2'),
  th: TagComponent('th', 'border px-4 py-2'),
};

export default components;