import React from "react";

export default function Subscribe() {
  return (
    <section className="py-16 flex flex-col items-center justify-center">
      <div
        id="revue-embed"
        className="w-full flex flex-col items-center justify-center max-w-6xl space-y-3"
      >
        <span className="text-xl font-semibold">
          Join our PM Insider Newsletter
        </span>
        <form
          action="https://www.getrevue.co/profile/myproducthub/add_subscriber"
          method="post"
          id="revue-form"
          name="revue-form"
          target="_blank"
          className="flex flex-row items-center justify-center w-2/3 max-w-lg space-x-3"
        >
          <div className="revue-form-group">
            {/* <label htmlFor="member_email">Email address</label> */}
            <input
              className="revue-form-field"
              placeholder="Your email address..."
              type="email"
              name="member[email]"
              id="member_email"
            />
          </div>
          {/* <div className="revue-form-group">
            <label htmlFor="member_first_name">
              First name <span className="optional">(Optional)</span>
            </label>
            <input
              className="revue-form-field"
              placeholder="First name... (Optional)"
              type="text"
              name="member[first_name]"
              id="member_first_name"
            />
          </div>
          <div className="revue-form-group">
            <label htmlFor="member_last_name">
              Last name <span className="optional">(Optional)</span>
            </label>
            <input
              className="revue-form-field"
              placeholder="Last name... (Optional)"
              type="text"
              name="member[last_name]"
              id="member_last_name"
            />
          </div> */}
          <div className="revue-form-actions">
            <input
              type="submit"
              value="Subscribe"
              name="member[subscribe]"
              id="member_submit"
              className="cursor-pointer shadow bg-mph-ocean-blue hover:bg-mph-orange focus:shadow-outline focus:outline-none text-white font-bold p-4 rounded"
            />
          </div>
        </form>
        <div className="revue-form-footer text-xs">
          *By subscribing, you agree with Revue’s{" "}
          <a
            target="_blank"
            href="https://www.getrevue.co/terms"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://www.getrevue.co/privacy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <br />
          and also our Terms of Service and Privacy Policy.
        </div>
      </div>
    </section>
  );
}
